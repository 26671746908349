import React, { Component, Fragment } from "react";
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import '../assets/css/font-awesome.min.css';
import '../assets/fonts/fonts.css';
import './Custom.css';
import $ from 'jquery'
import * as APICONST from '../constants/Api';
import * as COMMONCONST from '../constants/Common';
import searchSmiley from '../assets/images/search-think-icon.svg';
import moment from 'moment-timezone';
var call;

class ProductListing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            perpage: 18,
            currentPage: 1,
            meta: {},
            totalLeft: 0,
            error: null,
            products: [],
            productsByYear: [],
            productsYearKeys: [],
            isLoading: false,
            isFirstpage: false,
            isFirstTimeLoad: true,
            isWatchList: this.props.isWatchList ? this.props.isWatchList : 0,
            isWatchlistDelete: this.props.isWatchlistDelete ? this.props.isWatchlistDelete : 0,
            search: localStorage.getItem('search') ? localStorage.getItem('search') : '',
            token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
            beforeFilter: {
                brand_id: '',
                year: '',
                release: 'view_all',
                models: [],
                most_views: '',
                most_likes: '',
                month: '',
                model: ''
            },
            showMostLikes: {
                'week': 'Week',
                'month': 'Month',
                '6month': '6 Month',
                'all_time': 'All Time',
                'date-range': 'Date Range'
            },
            showMostViews: {
                'week': 'Week',
                'month': 'Month',
                '6month': '6 Month',
                'all_time': 'All Time',
                'date-range': 'Date Range'
            },
            showReleses: {
                'past_releases': 'Past Release',
                'not_yes_released': 'Upcoming'
            },
            showMonths: {
                '01': 'January',
                '02': 'February',
                '03': 'March',
                '04': 'April',
                '05': 'May',
                '06': 'June',
                '07': 'July',
                '08': 'August',
                '09': 'September',
                '10': 'October',
                '11': 'November',
                '12': 'December',
            },
            filtersValues: ['week', 'month', '6month', 'all_time'],
            brandLists: [],
            subBrandLists: [],
            listingBrandList: [],
            listingModelsList: [],
            width: window.innerWidth,

        };
        this.getProducts = this.getProducts.bind(this);
        this.getMoreProducts = this.getMoreProducts.bind(this);
        this.like = this.like.bind(this);
        this.watchlist = this.watchlist.bind(this);
        this.resetYearMonth = this.resetYearMonth.bind(this);
        this.getBrandList = this.getBrandList.bind(this);
        this.addModels = this.addModels.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }


    componentWillReceiveProps(nextProps) {
        this.getProducts(true, nextProps);
    }

    componentDidMount() {
        localStorage.removeItem('search');
        this.getProducts();
        this.getBrandList();
        COMMONCONST.loadImage();
        this.handleScroll();
        window.addEventListener('scroll', this.handleScroll);
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth }, () => {

        });
    }


    updateSearch = e => {
        e.preventDefault()
    }

    componentDidUpdate() {
        COMMONCONST.loadImage();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    updateSort(sort) {
        this.props.updateSort(sort);
    }

    applyReleases(release) {
        let beforeFilterObj = this.state.beforeFilter;
        beforeFilterObj.release = release;
        this.setState({ beforeFilter: beforeFilterObj });
    }

    clearMostViews() {
        let beforeFilterObj = this.state.beforeFilter;
        beforeFilterObj.most_views = '';
        this.setState({ beforeFilter: beforeFilterObj });
    }

    clearMostViewsEffect() {
        let beforeFilterObj = this.state.beforeFilter;
        beforeFilterObj.most_views = '';
        this.setState({ beforeFilter: beforeFilterObj }, () => this.props.updateApplyFilter(this.state.beforeFilter));
    }

    clearMostLikes() {
        let beforeFilterObj = this.state.beforeFilter;
        beforeFilterObj.most_likes = '';
        this.setState({ beforeFilter: beforeFilterObj });
    }

    clearMostLikesEffect() {
        let beforeFilterObj = this.state.beforeFilter;
        beforeFilterObj.most_likes = '';
        this.setState({ beforeFilter: beforeFilterObj }, () => this.props.updateApplyFilter(this.state.beforeFilter));
    }

    clearPastUpcomming() {
        let beforeFilterObj = this.state.beforeFilter;
        beforeFilterObj.release = 'view_all';
        this.setState({ beforeFilter: beforeFilterObj });
    }

    clearPastUpcommingEffect() {
        let beforeFilterObj = this.state.beforeFilter;
        beforeFilterObj.release = 'view_all';
        this.setState({ beforeFilter: beforeFilterObj }, () => this.props.updateApplyFilter(this.state.beforeFilter));
    }

    applyMostLikes(mostlike) {
        let beforeFilterObj = this.state.beforeFilter;
        beforeFilterObj.most_views = '';
        beforeFilterObj.most_likes = mostlike;
        this.setState({ beforeFilter: beforeFilterObj });
    }

    applyMostViews(mostview) {
        let beforeFilterObj = this.state.beforeFilter;
        beforeFilterObj.most_likes = '';
        beforeFilterObj.most_views = mostview;

        this.setState({ beforeFilter: beforeFilterObj });
    }

    applyBrand(brand) {
        this.setState({ brand_id: brand });
    }

    applyButton() {
        $('.filters-blk-wrap').toggleClass('active-filter');
        $('.filters-blk').toggleClass('active-filter-blk');
        this.props.updateApplyFilter(this.state.beforeFilter);
    }

    resetYearMonth() {
        let beforeFilterObj = this.state.beforeFilter;
        beforeFilterObj.year = '';
        beforeFilterObj.month = '';
        this.setState({ beforeFilter: beforeFilterObj });
    }

    addModels() {
        let beforeFilterObj = this.state.beforeFilter;
        if (beforeFilterObj.models === undefined) {
            beforeFilterObj.models = [];
        }
        if (!beforeFilterObj.models.includes(this.state.beforeFilter.model)) {
            beforeFilterObj.models.push(parseInt(this.state.beforeFilter.model))
            this.setState({ beforeFilter: beforeFilterObj })
        }
    }

    getMoreProducts() {
        let page = this.state.currentPage + 1;
        this.setState({
            currentPage: page
        }, () => this.getProducts());

    }

    like(id) {
        axios({
            method: "get",
            url: APICONST.LIKES(id),
            headers: { "content-type": "application/json" }
        })
            .then(response => {
                if (typeof (response.data.result.data) !== undefined && response.data.result.data === 1) {
                    let likeCount = parseInt($('#' + id + '-like-hide').text()) + 1;
                    let likeCountShow = COMMONCONST.makeFriendly(likeCount);
                    $('#' + id + '-like-hide').text(likeCount);
                    $('#' + id + '-like').text(likeCountShow);
                    $('#' + id + '-like').parent('li').addClass('active');
                } else {
                    let likeCount = parseInt($('#' + id + '-like-hide').text()) - 1;
                    let likeCountShow = COMMONCONST.makeFriendly(likeCount);
                    $('#' + id + '-like-hide').text(likeCount);
                    $('#' + id + '-like').text(likeCountShow);
                    $('#' + id + '-like').parent('li').removeClass('active');
                }
            })
            .catch(error => {
            });
    }

    watchlist(id) {
        COMMONCONST.setCookie('watchlist', true);
        let headers = { "content-type": "application/json" };
        axios({
            method: "get",
            url: APICONST.WATCHLIST(id),
            headers: headers,
            params: { 'watchlist-cookie': COMMONCONST.getCookie('watchlist') }
        })
            .then(response => {
                if (typeof (response.data.result.data) !== undefined && response.data.result.data === 1) {
                    let watchCount = COMMONCONST.makeFriendly(parseInt($('#' + id + '-watchlist-hide').text()) + response.data.result.add);
                    $('#' + id + '-watchlist').text(watchCount);
                    $('#' + id + '-watchlist').parent('li').addClass('active');
                } else {
                    $('#' + id + '-watchlist').parent('li').removeClass('active');
                    if (this.state.isWatchList === 1) {
                        this.getProducts(true, this.props);
                        // $('#' + id + '-watchlist').closest('.product-list').remove();
                        // if ($('.allproducts').find('.product-list').length == 0) {
                        //     // this.setState({ products: [], productsByYear: [], productsYearKeys: []})
                        // }
                    }
                }
            })
            .catch(error => {
            });
    }

    getProducts(isFilter = false, props = false) {
        let page;
        let sort;
        let archive;
        let search;
        let by_Release;
        let brand;
        let sub_category;
        let category;
        let most_likes;
        let most_views;
        let year;
        let month;
        let brand_id;
        let model_ids;
        let model_slug;
        if (!isFilter) {
            most_likes = this.props.most_likes;
            most_views = this.props.most_views;
            brand_id = this.props.brand_id;
            year = this.props.year;
            month = this.props.month;
            page = this.state.currentPage;
            if (page == 1) {
                this.setState({
                    isFirstpage: true
                });
            }
            sort = this.props.sort;
            by_Release = this.props.release;
            if (this.props.archive && this.props.archive.join()) {
                archive = this.props.archive.join();
            }
            if (this.props.models && this.props.models.join()) {
                model_ids = this.props.models.join();
            }
            if (this.props.brand && this.props.brand.join()) {
                brand = this.props.brand.join();
            }
            if (this.props.search) {
                search = this.props.search;
            } else if (this.props.searchMobile) {
                search = this.props.searchMobile;
            } else if (this.state.search) {
                search = this.state.search;
            }
            if (this.props.sub_category) {
                sub_category = this.props.sub_category;
            }
            if (this.props.category) {
                category = this.props.category;
            }

            if (this.props.model_slug) {
                model_slug = this.props.model_slug;
            }
        } else {
            this.setState({
                currentPage: 1,
                isFirstpage: true
            });
            if (props.sort == '') {
                this.updateSort('by_date');
            }
            sort = props.sort == '' ? 'by_date' : props.sort;
            by_Release = props.release;
            page = 1;
            if (props.archive && props.archive.join()) {
                archive = props.archive.join();
            }
            if (props.brand && props.brand.join()) {
                brand = props.brand.join();
            }
            if (props.models && props.models.join()) {
                model_ids = props.models.join();
            }
            if (props.search) {
                search = props.search;
            } else if (props.searchMobile) {
                search = props.searchMobile;
            } else if (this.state.search) {
                search = this.state.search;
            }

            if (props.sub_category) {
                sub_category = props.sub_category;
            }
            if (props.category) {
                category = props.category;
            }
            if (props.model_slug) {
                model_slug = props.model_slug;
            }
            most_likes = props.most_likes;
            most_views = props.most_views;
            year = props.year;
            month = props.month;
            brand_id = props.brand_id;
        }
        let perpage = this.state.perpage;
        let paramsValues = {
            perpage: perpage,
            page: page,
            field: sort,
            archive: archive,
            search: search,
            by_release: by_Release,
            brand: brand,
            is_watchlist: this.state.isWatchList,
            is_watchlist_delete: this.state.isWatchlistDelete,
            sub_category: sub_category,
            category: category,
            cookie: COMMONCONST.getCookie('watchlist'),
            is_mobile: 1,
            most_likes: most_likes,
            most_views: most_views,
            year: year,
            month: month,
            brand_id: brand_id,
            model_ids: model_ids,
            model_slug: model_slug
        }
        if (call) {
            call.cancel();
        }
        call = axios.CancelToken.source();

        this.setState({
            isLoading: true
        });
        console.log(this.state.isLoading)
        let headers = { "content-type": "application/json" };
        axios({
            method: "get",
            url: APICONST.PRODUCTLISTING,
            headers: headers,
            cancelToken: call.token,
            params: paramsValues
        })
            .then(response => {
                let products;
                let productsByYear;
                let productsYearKeys;
                if (isFilter) {
                    products = [];
                    productsByYear = [];
                    productsYearKeys = [];
                } else {
                    products = this.state.products;
                    productsByYear = this.state.productsByYear;
                    productsYearKeys = this.state.productsYearKeys;
                }
                if (this.props.most_likes != '' || this.props.most_views != '') {
                    products = products.concat(response.data.result.data.data);
                } else {
                    response.data.result.data.data.forEach((value) => {
                        if (value.is_past_release == "0") {
                            // products.push(value);
                            let uKey = ''
                            if (value.releaseYear && value.releaseMonth && value.releaseDay && value.releaseDay !== '00') {
                                uKey = value.releaseYear + '-' + value.releaseMonth + '-' + value.releaseDay;
                            } else if (value.releaseYear && value.releaseMonth && value.releaseMonth !== '00') {
                                uKey = value.releaseYear + '-' + value.releaseMonth;
                            } else {
                                uKey = value.releaseYear;
                            }
                            if (productsByYear[uKey] === undefined) {
                                productsByYear[uKey] = [];
                                productsByYear[uKey].push(value);
                                productsYearKeys.push(uKey);
                            } else {
                                productsByYear[uKey].push(value);
                            }
                        } else {
                            if (productsByYear[value.releaseYear] === undefined) {
                                productsByYear[value.releaseYear] = [];
                                productsByYear[value.releaseYear].push(value);
                                productsYearKeys.push(value.releaseYear);
                            } else {
                                productsByYear[value.releaseYear].push(value);
                            }
                        }
                    });
                }
                let meta = response.data.result.data.meta;
                let totalLeft = meta.total - (meta.page * meta.perpage);
                totalLeft = (totalLeft < 0) ? 0 : totalLeft;
                if (meta.last_updated) {
                    // this.props.lastUpdated(meta.last_updated);
                    $('.last-updated').show();
                    $('.last-update-date').text(meta.last_updated);
                } else {
                    $('.last-updated').hide();
                }
                this.setState({
                    products: products,
                    productsByYear: productsByYear,
                    meta: response.data.result.data.meta,
                    totalLeft: totalLeft,
                    productsYearKeys: productsYearKeys,
                    isLoading: false,
                    isFirstTimeLoad: false,
                    isFirstpage: false
                });
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                } else {
                    // this.setState({ error: error.message, isLoading: false, isFirstTimeLoad: false, isFirstpage: false })
                }
            }
            );
    }

    resetFilter() {
        let beforeFilterObj = this.state.beforeFilter;
        beforeFilterObj.most_views = '';
        beforeFilterObj.most_likes = '';
        beforeFilterObj.year = '';
        beforeFilterObj.month = '';
        beforeFilterObj.brand_id = '';
        beforeFilterObj.release = '';
        beforeFilterObj.models = [];
        beforeFilterObj.model = '';
        this.setState({ beforeFilter: beforeFilterObj });
        this.getSubBrandList(1000);
    }

    resetFilterAtTime() {
        let beforeFilterObj = this.state.beforeFilter;
        beforeFilterObj.most_views = '';
        beforeFilterObj.most_likes = '';
        beforeFilterObj.year = '';
        beforeFilterObj.month = '';
        beforeFilterObj.brand_id = '';
        beforeFilterObj.release = '';
        beforeFilterObj.models = [];
        beforeFilterObj.model = '';
        this.setState({ beforeFilter: beforeFilterObj }, () => this.props.updateApplyFilter(this.state.beforeFilter));
        this.getSubBrandList(1000);
    }

    clearYearAndMonth() {
        let beforeFilterObj = this.state.beforeFilter;
        beforeFilterObj.year = '';
        beforeFilterObj.month = '';
        this.setState({ beforeFilter: beforeFilterObj }, () => this.props.updateApplyFilter(this.state.beforeFilter));
    }

    applyCloseFIlter() {
        $('.filters-blk-wrap').toggleClass('active-filter');
        $('.filters-blk').toggleClass('active-filter-blk');
        let beforeFilterObj = this.state.beforeFilter;
        beforeFilterObj.most_views = this.props.most_views;
        beforeFilterObj.most_likes = this.props.most_likes;
        beforeFilterObj.year = this.props.year;
        beforeFilterObj.month = this.props.month;
        beforeFilterObj.brand_id = this.props.brand_id;
        beforeFilterObj.release = this.props.release;
        beforeFilterObj.models = this.props.models;
        this.setState({ beforeFilter: beforeFilterObj });
    }

    getBrandList() {
        let headers = { "content-type": "application/json" };
        axios({
            method: "get",
            url: APICONST.BRANDLISTING,
            headers: headers,
        })
            .then(response => {
                this.setState({
                    brandLists: response.data.result.data
                });
            })
            .catch(error => {
            }
            );
    }

    getSubBrandList(brand) {
        let headers = { "content-type": "application/json" };
        axios({
            method: "get",
            url: APICONST.MODELLISTING,
            headers: headers,
            params: { 'brand_id': brand }
        })
            .then(response => {
                this.setState({
                    subBrandLists: response.data.result.data
                });
            })
            .catch(error => {
            }
            );
    }

    handleScroll() {
        var loadMore = $("#product_listing .loadmore-btn .load-more");

        if (loadMore.length > 0) {
            var elementTop = loadMore.offset().top + 100;
            var elementBottom = elementTop + loadMore.outerHeight();

            var viewportTop = $(window).scrollTop();
            var viewportBottom = viewportTop + $(window).height();

            if ((1 === this.state.currentPage || 2 === this.state.currentPage) && this.state.isLoading == false && (elementBottom > viewportTop && elementTop < viewportBottom)) {
                loadMore[0].click();
            }
        }
    }

    render() {
        $(function () {
            $('.load-more').click(function (e) {
                e.preventDefault();
                e.target.blur();
            });
        });
        let fireIcon = (
            <svg version="1.1" id="Layer_1" x="0px" y="0px" width="16.145px" height="21.492px" viewBox="0 0 16.145 21.492" enableBackground="new 0 0 16.145 21.492">
                <g>
                    <defs>
                        <rect id="SVGID_1_" y="0" width="16.145" height="21.492" />
                    </defs>
                    <clipPath id="SVGID_2_">
                        <use xlinkHref="#SVGID_1_" overflow="visible" />
                    </clipPath>
                    <path fill="none" stroke="#737373" strokeWidth="2" strokeMiterlimit="10" d="M15.139,11.8
                        c0.163,4.954-3.336,8.691-7.18,8.691C4.116,20.492,1,17.376,1,13.532C1,8.778,6.11,4.801,8.187,2.359
                        c0.19,1.43,0.229,5.68,1.517,7.691C10.775,11.726,13.326,12.243,15.139,11.8z"/>
                </g>
            </svg>

        )

        let watchlistfireIcon = (
            <svg width="22" height="28" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.1887 27.0735C5.55663 27.0735 0.976562 22.599 0.976562 17.0967C0.976562 11.8576 5.3257 7.52099 8.50737 4.34998C9.2643 3.59796 9.98274 2.88354 10.5087 2.28193L12.369 0.138672L12.7539 2.93368C12.8052 3.32222 12.8437 3.8737 12.9078 4.52545C13.0874 6.69377 13.3825 10.3285 14.5115 12.0707C15.5507 13.6625 18.232 14.1764 20.1051 13.7252L21.6446 13.3617L21.6959 14.9033C21.8114 18.3751 20.5541 21.6966 18.2448 24.0278C16.2819 25.9831 13.7802 27.0735 11.1887 27.0735ZM10.4574 5.99189C10.4189 6.02949 10.3805 6.06709 10.342 6.10469C7.4682 8.9749 3.54243 12.8854 3.54243 17.0967C3.54243 21.2203 6.96785 24.5668 11.1887 24.5668C13.0746 24.5668 14.922 23.7521 16.3974 22.2731C17.9113 20.744 18.8606 18.6383 19.0787 16.3698C16.5898 16.47 13.7674 15.5801 12.3433 13.4118C11.0989 11.5067 10.6755 8.44849 10.4574 5.99189Z" fill="#707070" /> </svg>
        )

        let viewIcon = (
            <svg width="16" height="10" viewBox="0 0 16 10" fill="none">
                <path d="M8.19327 9.8278C4.34185 9.8278 1.34774 5.71358 1.21785 5.54215L0.977539 5.1993L1.22434 4.85645C1.34774 4.6789 4.34185 0.570801 8.19977 0.570801C12.0512 0.570801 15.0453 4.68502 15.1752 4.85645L15.422 5.1993L15.1752 5.54215C15.0453 5.7197 12.0512 9.8278 8.19327 9.8278ZM2.56877 5.1993C3.32866 6.12378 5.60834 8.60333 8.19327 8.60333C10.7782 8.60333 13.0579 6.12378 13.8178 5.1993C13.0579 4.27483 10.7782 1.79527 8.19327 1.79527C5.60834 1.79527 3.32866 4.27483 2.56877 5.1993Z" fill="#707070" />
                <path d="M8.19321 8.19923C6.4396 8.19923 5.01074 6.85232 5.01074 5.19929C5.01074 3.54626 6.4396 2.19934 8.19321 2.19934C9.94681 2.19934 11.3757 3.54626 11.3757 5.19929C11.3757 6.85232 9.9533 8.19923 8.19321 8.19923ZM8.19321 3.42381C7.15403 3.42381 6.30971 4.21971 6.30971 5.19929C6.30971 6.17886 7.15403 6.97477 8.19321 6.97477C9.23238 6.97477 10.0767 6.17886 10.0767 5.19929C10.0767 4.21971 9.23238 3.42381 8.19321 3.42381Z" fill="#707070" />
            </svg>
        )

        let wtcviewIcon = (
            <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M15.324 19.5632C7.59095 19.5632 1.57925 11.2755 1.31844 10.9302L0.835938 10.2396L1.33148 9.54895C1.57925 9.1913 7.59095 0.916016 15.337 0.916016C23.0701 0.916016 29.0818 9.20363 29.3426 9.54895L29.8382 10.2396L29.3426 10.9302C29.0818 11.2879 23.0701 19.5632 15.324 19.5632ZM4.03088 10.2396C5.55662 12.1018 10.1339 17.0966 15.324 17.0966C20.5142 17.0966 25.0914 12.1018 26.6171 10.2396C25.0914 8.37734 20.5142 3.38257 15.324 3.38257C10.1339 3.38257 5.55662 8.37734 4.03088 10.2396Z" fill="#707070" /> <path d="M15.3235 16.2824C11.8025 16.2824 8.93359 13.5692 8.93359 10.2393C8.93359 6.90949 11.8025 4.19629 15.3235 4.19629C18.8444 4.19629 21.7134 6.90949 21.7134 10.2393C21.7134 13.5692 18.8575 16.2824 15.3235 16.2824ZM15.3235 6.66283C13.237 6.66283 11.5417 8.26609 11.5417 10.2393C11.5417 12.2126 13.237 13.8158 15.3235 13.8158C17.41 13.8158 19.1053 12.2126 19.1053 10.2393C19.1053 8.26609 17.41 6.66283 15.3235 6.66283Z" fill="#707070" /> </svg>
        )

        let wtcBookIcon = (
            <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M0.895508 21.4828V0.763672H17.4607V21.4828L9.17812 15.6457L0.895508 21.4828ZM9.17812 12.6669L15.0066 16.7794V3.17567H3.34962V16.7794L9.17812 12.6669Z" fill="#707070" /> </svg>
        )

        const closeIcon = (
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none">
                <path d="M8 0.867692L7.13231 0L4 3.13231L0.867692 0L0 0.867692L3.13231 4L0 7.13231L0.867692 8L4 4.86769L7.13231 8L8 7.13231L4.86769 4L8 0.867692Z" fill="#707070" />
            </svg>
        )
        const filterIcon = (
            <svg width="16" height="13" viewBox="0 0 16 13" fill="none">
                <path d="M15.77 1.9502H0V3.9502H15.77V1.9502Z" fill="white" />
                <path d="M12.8899 0H10.8899V5.9H12.8899V0Z" fill="white" />
                <path d="M15.77 8.98047H0V10.9805H15.77V8.98047Z" fill="white" />
                <path d="M4.87988 7.01953H2.87988V12.9195H4.87988V7.01953Z" fill="white" />
            </svg>
        )
        const productBlock = (item, key) => {
            return <li key={key} className="product-list">
                <div className="product-item">
                    <Link target="_blank" to={'/' + item.slug} className="product-img"><img src={item.product_thumbnail} alt="img" /></Link>
                    {item.just_added != '' ? <span className="just-added">{item.just_added}</span> : ''}
                    <div className="prod-list-new-cnt">
                        <div className="prod-details">
                            <h4 className="prod-name">
                                <Link target="_blank" to={'/' + item.slug}>{item.full_name}</Link>
                                {item.product_nickname && <span className="prod-color"> “{item.product_nickname}”</span>}
                            </h4>
                            {(this.props.most_likes != '' || this.props.most_views != '' || item.is_past_release == "1") ?
                                <h4 className="prod-date">{formatDateIndividual(item.releaseDay, item.releaseMonth, item.releaseYear)}</h4> : ''}
                            <h2 className="prod-price">{item.price}</h2>
                        </div>
                        <div className="infos">
                            {/* <span className={item.is_release == "0" ? "datetime red" : "datetime"}>{item.show_date}</span> */}
                            <ul className="info-count">
                                <li data-toggle="tooltip" data-placement="top">
                                    <div className="tooltip"><div className="tooltip-inner"><span className="arrow"></span>Views</div></div>
                                    <i className="eye-icon">{viewIcon}</i> {COMMONCONST.makeFriendly(item.views_count)} </li>
                                <li title="Likes" className={item.is_liked === 1 ? 'active' : ''}>
                                    <div className="tooltip"><div className="tooltip-inner"><span className="arrow"></span>Likes</div></div>
                                    <i onClick={() => this.like(item.id)} className="fire">{fireIcon}</i> <span id={item.id + "-like"}> {COMMONCONST.makeFriendly(item.likes_counts)} </span>
                                    <span id={item.id + "-like-hide"} className="hide"> {item.likes_counts} </span>
                                </li>
                                <li title="Watchlist" className={item.is_watchlisted === 1 ? 'active' : ''}>
                                    <div className="tooltip"><div className="tooltip-inner"><span className="arrow"></span>Watchlist</div></div>
                                    <i onClick={() => this.watchlist(item.id)} className="fa fa-bookmark-o"></i> <span id={item.id + "-watchlist"}> {COMMONCONST.makeFriendly(item.watch_list_count)} </span>
                                    <span id={item.id + "-watchlist-hide"} className="hide"> {item.watch_list_count} </span>
                                </li>
                            </ul>
                        </div>
                        <div className="prod-detail-cnt">
                            <p>{item.show_genders}</p>
                            <p>{item.sku}</p>
                            <p>{item.show_regions}</p>
                        </div>
                    </div>
                </div>
            </li>
        }
        const brandOptionList = (k) => {
            return <option value={k.id}>{k.name}</option>
        }
        const formatDate = (year) => {
            if (year.length <= 4) {
                return year + ' Releases';
            } else {
                let mydate = new Date(year);
                let currentD = moment.tz(year + ' 00:00:00', 'America/New_York');
                if (year.length == 7) {
                    // return mydate.toLocaleString('en-us', { month: 'long' }) + ', ' + mydate.getFullYear();
                    currentD = moment.tz(year + '-01 00:00:00', 'America/New_York');
                    return moment(currentD).format('MMMM') + ', ' + moment(currentD).format('YYYY');
                } else {
                    // return mydate.toLocaleString('en-us', { month: 'long' }) + ' ' + mydate.getDate() + ', ' + mydate.getFullYear();
                    return moment(currentD).format('MMMM') + ' ' + moment(currentD).format('DD') + ', ' + moment(currentD).format('YYYY');
                }
            }
        }

        const formatDateIndividual = (day, month, yea) => {
            if (typeof yea != 'string' || typeof month != 'string' || typeof day != 'string') {
                return '';
            }
            let year;
            if (yea && month && day && day !== '00') {
                year = yea + '-' + month + '-' + day;
            } else if (yea && month && month !== '00') {
                year = yea + '-' + month;
            } else {
                year = yea;
            }

            if (year.length <= 4) {
                return year;
            } else {
                let mydate = new Date(year);
                let currentD = moment.tz(year + ' 00:00:00', 'America/New_York');
                if (year.length == 7) {
                    // return mydate.toLocaleString('en-us', { month: 'long' }) + ', ' + mydate.getFullYear();
                    currentD = moment.tz(year + '-01 00:00:00', 'America/New_York');
                    return moment(currentD).format('MMMM') + ', ' + moment(currentD).format('YYYY');
                } else {
                    // return mydate.toLocaleString('en-us', { month: 'long' }) + ' ' + mydate.getDate() + ', ' + mydate.getFullYear();
                    return moment(currentD).format('MMMM') + ' ' + moment(currentD).format('DD') + ', ' + moment(currentD).format('YYYY');
                }
            }
        }

        const weekFormatDate = (year) => {
            if (year.length >= 8) {
                // let mydate = new Date(year);
                let currentD = moment.tz(year + ' 00:00:00', 'America/New_York');
                // return ' ' + mydate.toLocaleString('en-us', { weekday: 'long' });
                return ' ' + moment(currentD).format('dddd');
            } else {
                return '';
            }
        }

        return (
            <div className={`product-main-list-blk ${this.props.isSearch ? 'is_search_list-blk' : ''}`}>
                <div className={`product-list-header ${(this.props.searchForm || this.props.isSearch) && this.props.searchFilter ? 'search-with-filter' : ''}`}>
                    <div className="prod-list-title-wrap">
                        {this.props.isSearch ? <h4 className="large-main-title">Search</h4> : <>
                            <h4 className="large-main-title">{this.props.title}</h4>
                            {this.props.subTitle && <span className="sub-title">{this.props.subTitle}</span>}
                        </>}
                    </div>
                    <div className={`filters-blk-wrap`}>
                        {this.props.searchForm || this.props.isSearch ?
                            <form
                                className={`form-inline filter-search ${this.props.hideResSearch ? 'search-res-none' : ''}`}
                                onSubmit={this.updateSearch}
                            >
                                <input
                                    className='form-control'
                                    value={this.state.search}
                                    onChange={e =>
                                        this.setState({
                                            search: e.target.value,
                                        }, () => this.getProducts(true))
                                    }
                                    type='search'
                                    placeholder='Search'
                                    aria-label='Search'
                                />
                                {this.state.search === '' ?
                                    <span className='btn btn-search' onClick={this.openmenu}></span>
                                    :
                                    <span className='btn btn-close' onClick={e =>
                                        this.setState({
                                            search: ''
                                        }, () => this.getProducts(true))
                                    }></span>
                                }
                            </form> : ''}
                        {this.props.searchFilter ?
                            <Fragment>
                                <span className="filter-btn" onClick={() => this.applyCloseFIlter()}>{filterIcon}Filters</span>
                                <div className="non-active-selected-filters">
                                    <div className="selected-filters">
                                        {this.props.year || this.props.month || (this.props.release && this.props.release != 'view_all') || this.props.most_likes || this.props.most_views
                                            || this.props.brand_id ?
                                            <span className="reset-filter" onClick={() => this.resetFilterAtTime()}>Reset</span> : ''}
                                        {
                                            this.props.year || this.props.month ? <span className="filter-span">{this.props.year} {this.props.month ? ' / ' : ''} {this.state.showMonths[this.props.month]}<i className="filter-close-icon" onClick={() => this.clearYearAndMonth()}>{closeIcon}</i></span> : ''
                                        }
                                        {
                                            this.props.release && this.props.release != 'view_all' ? <span className="filter-span">{this.props.release === 'past_releases' ? 'Past Release' : 'Upcoming'}<i className="filter-close-icon" onClick={() => this.clearPastUpcommingEffect()}>{closeIcon}</i></span> : ''
                                        }
                                        {
                                            this.props.most_likes ? <span className="filter-span">Most Likes / {this.state.showMostLikes[this.props.most_likes]}<i className="filter-close-icon" onClick={() => this.clearMostLikesEffect()}>{closeIcon}</i></span> : ''
                                        }
                                        {
                                            this.props.most_views ? <span className="filter-span">Most Views / {this.state.showMostViews[this.props.most_views]}<i className="filter-close-icon" onClick={() => this.clearMostViewsEffect()}>{closeIcon}</i></span> : ''
                                        }
                                        {this.props.brand_id ? <span className="filter-span">{this.state.brandLists.map((brand, ind) =>
                                            <>{brand.id == this.props.brand_id ? brand.name : ''}</>
                                        )}<i className="filter-close-icon" onClick={() => {
                                            let beforeFilterObj = this.state.beforeFilter;
                                            beforeFilterObj.brand_id = '';
                                            beforeFilterObj.models = [];
                                            this.getSubBrandList(1000)
                                            this.setState({ beforeFilter: beforeFilterObj }, () => this.props.updateApplyFilter(this.state.beforeFilter))
                                        }}>{closeIcon}</i></span> : ''}

                                        {this.props.models && this.props.models.length > 0 ? <>
                                            {this.props.models.map((m, i) =>
                                                <>
                                                    {this.state.subBrandLists.map((brand, ind) =>
                                                        <>{brand.id == m ? <span className="filter-span">{this.state.brandLists.map((brand, ind) =>
                                                            <>{brand.id == this.props.brand_id ? brand.name : ''}</>
                                                        )} / {brand.name}<i className="filter-close-icon" onClick={() => {
                                                            let beforeFilterObj = this.state.beforeFilter;
                                                            if (beforeFilterObj.models === undefined) {
                                                                beforeFilterObj.models = [];
                                                            }
                                                            if (beforeFilterObj.models.includes(m)) {
                                                                beforeFilterObj.models.pop(parseInt(m))
                                                                this.setState({ beforeFilter: beforeFilterObj }, () => this.props.updateApplyFilter(this.state.beforeFilter))
                                                            }
                                                        }}>{closeIcon}</i></span> : ''}</>
                                                    )}
                                                </>
                                            )}</> : ''}
                                    </div>
                                </div>
                            </Fragment> : ''}
                        <div className="filters-blk">
                            <div className="filter-btn-blk">
                                <span className="filter-btn" onClick={() => this.applyCloseFIlter()}>{filterIcon}Filters</span>
                            </div>
                            <div className="filters-inner--blk">
                                <div className="filter-row">
                                    <div className="filter-col-left">
                                        <label>By Date</label>
                                    </div>
                                    <div className="filter-col-right">
                                        <span className={this.state.beforeFilter.release === 'past_releases' ? 'filter-span active-filter' : 'filter-span'} onClick={() => this.applyReleases('past_releases')}>Past Release</span>
                                        <span className={this.state.beforeFilter.release === 'not_yes_released' ? 'filter-span active-filter' : 'filter-span'} onClick={() => this.applyReleases('not_yes_released')}>Upcoming</span>
                                    </div>
                                </div>
                                <div className="filter-row">
                                    <div className="filter-col-left">
                                        <label>Year / Month</label>
                                    </div>
                                    <div className="filter-col-right">
                                        <div className="filter-select-wrapper filter-by-year">
                                            <select className="filter-select" value={this.state.beforeFilter.year}
                                                onChange={e => {
                                                    let beforeFilterObj = this.state.beforeFilter;
                                                    beforeFilterObj.year = e.target.value;
                                                    if (e.target.value == "") {
                                                        beforeFilterObj.month = "";
                                                    }
                                                    if (this.state.filtersValues.includes(beforeFilterObj.most_likes)) {
                                                        beforeFilterObj.most_likes = '';
                                                    }
                                                    if (this.state.filtersValues.includes(beforeFilterObj.most_views)) {
                                                        beforeFilterObj.most_views = '';
                                                    }
                                                    this.setState({ beforeFilter: beforeFilterObj })
                                                }
                                                }>
                                                <option value="">Year</option>
                                                <option value="2022">2022</option>
                                                <option value="2021">2021</option>
                                                <option value="2020">2020</option>
                                                <option value="2019">2019</option>
                                                <option value="2018">2018</option>
                                                <option value="2017">2017</option>
                                                <option value="2016">2016</option>
                                            </select>
                                        </div>
                                        <div className="filter-select-wrapper filter-by-month">
                                            <select className="filter-select" value={this.state.beforeFilter.month}
                                                onChange={e => {
                                                    let beforeFilterObj = this.state.beforeFilter;
                                                    beforeFilterObj.month = e.target.value;
                                                    if (this.state.filtersValues.includes(beforeFilterObj.most_likes)) {
                                                        beforeFilterObj.most_likes = '';
                                                    }
                                                    if (this.state.filtersValues.includes(beforeFilterObj.most_views)) {
                                                        beforeFilterObj.most_views = '';
                                                    }
                                                    this.setState({ beforeFilter: beforeFilterObj })
                                                }
                                                }>
                                                <option value="">Month</option>
                                                <option value="01">January</option>
                                                <option value="02">February</option>
                                                <option value="03">March</option>
                                                <option value="04">April</option>
                                                <option value="05">May</option>
                                                <option value="06">June</option>
                                                <option value="07">July</option>
                                                <option value="08">August</option>
                                                <option value="09">September</option>
                                                <option value="10">October</option>
                                                <option value="11">November</option>
                                                <option value="12">December</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-row">
                                    <div className="filter-col-left">
                                        <label>Most Liked</label>
                                    </div>
                                    <div className="filter-col-right">
                                        {this.state.beforeFilter.year != '' ?
                                            <span className={this.state.beforeFilter.most_likes === 'date-range' ? 'filter-span active-filter' : 'filter-span'} onClick={() => this.applyMostLikes('date-range')}>Date Range</span>
                                            : ''}
                                        <span className={this.state.beforeFilter.most_likes === 'week' ? 'filter-span active-filter' : this.state.beforeFilter.year != "" ? 'filter-span disabled' : 'filter-span'} onClick={() => this.applyMostLikes('week')}>1w</span>
                                        <span className={this.state.beforeFilter.most_likes === 'month' ? 'filter-span active-filter' : this.state.beforeFilter.year != "" ? 'filter-span disabled' : 'filter-span'} onClick={() => this.applyMostLikes('month')}>1m</span>
                                        <span className={this.state.beforeFilter.most_likes === '6month' ? 'filter-span active-filter' : this.state.beforeFilter.year != "" ? 'filter-span disabled' : 'filter-span'} onClick={() => this.applyMostLikes('6month')}>6m</span>
                                        <span className={this.state.beforeFilter.most_likes === 'all_time' ? 'filter-span active-filter' : this.state.beforeFilter.year != "" ? 'filter-span disabled' : 'filter-span'} onClick={() => this.applyMostLikes('all_time')}>all time</span>
                                    </div>
                                </div>
                                <div className="filter-row">
                                    <div className="filter-col-left">
                                        <label>Most Viewed</label>
                                    </div>
                                    <div className="filter-col-right">
                                        {this.state.beforeFilter.year != '' ?
                                            <span className={this.state.beforeFilter.most_views === 'date-range' ? 'filter-span active-filter' : 'filter-span'} onClick={() => this.applyMostViews('date-range')}>Date Range</span>
                                            : ''}
                                        <span className={this.state.beforeFilter.most_views === 'week' ? 'filter-span active-filter' : this.state.beforeFilter.year != "" ? 'filter-span disabled' : 'filter-span'} onClick={() => this.applyMostViews('week')}>1w</span>
                                        <span className={this.state.beforeFilter.most_views === 'month' ? 'filter-span active-filter' : this.state.beforeFilter.year != "" ? 'filter-span disabled' : 'filter-span'} onClick={() => this.applyMostViews('month')}>1m</span>
                                        <span className={this.state.beforeFilter.most_views === '6month' ? 'filter-span active-filter' : this.state.beforeFilter.year != "" ? 'filter-span disabled' : 'filter-span'} onClick={() => this.applyMostViews('6month')}>6m</span>
                                        <span className={this.state.beforeFilter.most_views === 'all_time' ? 'filter-span active-filter' : this.state.beforeFilter.year != "" ? 'filter-span disabled' : 'filter-span'} onClick={() => this.applyMostViews('all_time')}>all time</span>
                                    </div>
                                </div>
                                {this.props.brandfilter !== false ? <div className="filter-row">
                                    <div className="filter-col-left">
                                        <label>Brand / Model</label>
                                    </div>
                                    <div className="filter-col-right">
                                        <div className="filter-span-blk">
                                            <span className={this.state.beforeFilter.brand_id == 2 ? 'filter-span active-filter' : 'filter-span'} onClick={() => {
                                                let beforeFilterObj = this.state.beforeFilter;
                                                beforeFilterObj.brand_id = 2;
                                                this.getSubBrandList(2)
                                                this.setState({ beforeFilter: beforeFilterObj })
                                            }}>Nike</span>
                                            <span className={this.state.beforeFilter.brand_id == 1 ? 'filter-span active-filter' : 'filter-span'} onClick={() => {
                                                let beforeFilterObj = this.state.beforeFilter;
                                                beforeFilterObj.brand_id = 1;
                                                this.getSubBrandList(1)
                                                this.setState({ beforeFilter: beforeFilterObj })
                                            }}>Jordan</span>
                                            <span className={this.state.beforeFilter.brand_id == 5 ? 'filter-span active-filter' : 'filter-span'} onClick={() => {
                                                let beforeFilterObj = this.state.beforeFilter;
                                                beforeFilterObj.brand_id = 5;
                                                this.getSubBrandList(5)
                                                this.setState({ beforeFilter: beforeFilterObj })
                                            }}>adidas</span>
                                            <span className={this.state.beforeFilter.brand_id == 35 ? 'filter-span active-filter' : 'filter-span'} onClick={() => {
                                                let beforeFilterObj = this.state.beforeFilter;
                                                beforeFilterObj.brand_id = 35;
                                                this.getSubBrandList(35)
                                                this.setState({ beforeFilter: beforeFilterObj })
                                            }}>Yeezy</span>
                                        </div>
                                    </div>
                                    <div className="filter-dropdown-list">
                                        <div className="filter-select-wrapper filter-by-brand">
                                            <select className="filter-select" value={this.state.beforeFilter.brand_id} onChange={e => {
                                                this.state.beforeFilter.brand_id = e.target.value;
                                                if (e.target.value == '') {
                                                    this.setState({ subBrandLists: [] })
                                                } else {
                                                    this.getSubBrandList(e.target.value)
                                                }
                                            }
                                            }>
                                                <option value="">Brand</option>
                                                {this.state.brandLists.map((brand, ind) =>
                                                    brandOptionList(brand)
                                                )}
                                            </select>
                                        </div>
                                        <div className="filter-select-wrapper filter-by-modal">
                                            <select className="filter-select" value={this.state.beforeFilter.model} onChange={e => {
                                                let beforeFilterObj = this.state.beforeFilter;
                                                beforeFilterObj.model = e.target.value;
                                                this.setState({ beforeFilter: beforeFilterObj })
                                            }
                                            }>
                                                <option value="">Model</option>
                                                {this.state.subBrandLists.map((brand, ind) =>
                                                    brandOptionList(brand)
                                                )}
                                            </select>
                                        </div>
                                        <button className="btn-plus fa fa-plus" onClick={() => this.addModels()}></button>
                                    </div>
                                </div> : ''}
                                <div className="filter-row">
                                    <div className="selected-filters">
                                        <span className="reset-filter" onClick={() => this.resetFilter()}>Reset</span>
                                        {
                                            this.state.beforeFilter.release && this.state.beforeFilter.release != 'view_all' ? <span className="filter-span">{this.state.beforeFilter.release === 'past_releases' ? 'Past Release' : 'Upcoming'}<i className="filter-close-icon" onClick={() => this.clearPastUpcomming()}>{closeIcon}</i></span> : ''
                                        }
                                        {
                                            this.state.beforeFilter.most_likes ? <span className="filter-span">Most Likes / {this.state.showMostLikes[this.state.beforeFilter.most_likes]}<i className="filter-close-icon" onClick={() => this.clearMostLikes()}>{closeIcon}</i></span> : ''
                                        }
                                        {
                                            this.state.beforeFilter.most_views ? <span className="filter-span">Most Views / {this.state.showMostViews[this.state.beforeFilter.most_views]}<i className="filter-close-icon" onClick={() => this.clearMostViews()}>{closeIcon}</i></span> : ''
                                        }
                                        {this.state.beforeFilter.brand_id ? <span className="filter-span">{this.state.brandLists.map((brand, ind) =>
                                            <>{brand.id == this.state.beforeFilter.brand_id ? brand.name : ''}</>
                                        )}<i className="filter-close-icon" onClick={() => {
                                            let beforeFilterObj = this.state.beforeFilter;
                                            beforeFilterObj.brand_id = '';
                                            beforeFilterObj.models = [];
                                            this.getSubBrandList(1000)
                                            this.setState({ beforeFilter: beforeFilterObj })
                                        }}>{closeIcon}</i></span> : ''}

                                        {this.state.beforeFilter.models && this.state.beforeFilter.models.length > 0 ? <>
                                            {this.state.beforeFilter.models.map((m, i) =>
                                                <>
                                                    {this.state.subBrandLists.map((brand, ind) =>
                                                        <>{brand.id == m ? <span className="filter-span">{this.state.brandLists.map((brand, ind) =>
                                                            <>{brand.id == this.state.beforeFilter.brand_id ? brand.name : ''}</>
                                                        )} / {brand.name} <i className="filter-close-icon" onClick={() => {
                                                            let beforeFilterObj = this.state.beforeFilter;
                                                            if (beforeFilterObj.models === undefined) {
                                                                beforeFilterObj.models = [];
                                                            }
                                                            if (beforeFilterObj.models.includes(m)) {
                                                                beforeFilterObj.models.pop(parseInt(m))
                                                                this.setState({ beforeFilter: beforeFilterObj })
                                                            }
                                                        }}>{closeIcon}</i></span> : ''}</>
                                                    )}
                                                </>
                                            )}</> : ''}

                                        {/* <span className="filter-span">{this.state.beforeFilter.models.map((m, ind) =>
                                            <> {this.state.subBrandLists.map((sub) =>
                                                <>{sub.id == m ? sub.name : ''}</>
                                            )}
                                            </>
                                        )}{closeIcon}</span> */}
                                    </div>
                                </div>
                                <div className="filter-row">
                                    <div className="filters-btn-blk">
                                        <button className="apply-filter-btn" onClick={() => this.applyButton()}>Apply Filters</button>
                                        <button className="cancel-btn" onClick={() => this.applyCloseFIlter()}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="product_listing" className={this.state.isLoading && this.state.isFirstpage ? "prod_list sticky-loader-active" : "prod_list"}>
                    {this.state.isLoading && this.state.isFirstpage ? <div className="sticky-loader-wrap"><span className="sticky-loader-span"></span></div> : ''}
                    <ul className="allproducts">
                        {this.state.products.map((item, key) =>
                            productBlock(item, key)
                        )}
                    </ul>

                    <div className="allproducts-with-date-main">
                        {this.state.productsYearKeys.map((year) =>
                            <div className="allproducts-with-date" key={year}>
                                <p className={(year && year.length <= 4) ? "release-title only-release" : "release-title"}>{year ? formatDate(year) : 'N/A'}<span>{year ? weekFormatDate(year) : ''}</span> </p>
                                <ul className="allproducts">
                                    {this.state.productsByYear[year].map((item, key) =>
                                        productBlock(item, key)
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>

                    <div className="loadmore-btn">{this.state.totalLeft !== 0 ? <button className={this.state.isLoading ? "load-more is-loading" : "load-more"} onClick={this.getMoreProducts}>{this.state.width >= 768 ? 'Load More' : 'View More'} {/*<span className="count">({this.state.totalLeft})</span>*/} <i className="fa fa-spinner loadicon"></i> </button> : ''}</div>
                    {this.state.isWatchList !== 1 && this.state.products.length == 0 && this.state.productsByYear.length == 0 && this.state.productsYearKeys.length == 0 && !this.state.isFirstTimeLoad ?
                        <div className="no-search-found">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="search-found-blk">
                                        <div className="search-alert-icon">
                                            <img src={searchSmiley} />
                                        </div>
                                        <div className="search-txt">
                                            {(this.props.search != '' && this.props.search != undefined) || (this.props.searchMobile != '' && this.props.searchMobile != undefined) || this.state.search != '' ?
                                                <div>
                                                    {this.state.search != '' ?
                                                        <h5>No search results for “{this.state.search}” </h5>
                                                        :
                                                        <>
                                                            {this.props.search != '' ?
                                                                <h5>No search results for “{this.props.search}” </h5>
                                                                :
                                                                <h5>No search results for “{this.props.searchMobile}” </h5>
                                                            }
                                                        </>
                                                    }
                                                    <p>Make sure your keyword is spelled correctly</p>
                                                </div>
                                                : <h5>No search results found </h5>}
                                            {/* {this.props.search == '' && this.props.searchMobile == '' && this.state.search == '' ?
                                                <h5>No search results found </h5>
                                                : ''} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ''}
                    {this.state.isWatchList === 1 && this.state.products.length == 0 && this.state.productsByYear.length == 0 && this.state.productsYearKeys.length == 0 && !this.state.isFirstTimeLoad ?

                        <div className="watchlist-not-found">
                            {this.state.search != '' ? <div className="no-search-found">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="search-found-blk">
                                            <div className="search-alert-icon">
                                                <img src={searchSmiley} />
                                            </div>
                                            <div className="search-txt">
                                                {this.state.search != '' || this.state.searchMobile != '' ?
                                                    <div>
                                                        {this.state.search != '' ?
                                                            <h5>No search results for “{this.state.search}” </h5>
                                                            :
                                                            <>
                                                                {this.props.search != '' ?
                                                                    <h5>No search results for “{this.props.search}” </h5>
                                                                    :
                                                                    <h5>No search results for “{this.props.searchMobile}” </h5>
                                                                }
                                                            </>
                                                        }
                                                        <p>Make sure your keyword is spelled correctly</p>
                                                    </div>
                                                    : <h5>No search results found </h5>}
                                                {/* {this.state.search == '' && this.state.searchMobile == '' && this.state.search == '' ?
                                                    <h5>No search results found </h5>
                                                    : ''} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : <div className="wtch-not-fount-box">
                                <div className="wtch-col-img">
                                    <ul className="info-count">
                                        <li><i className="eye-icon">{wtcviewIcon}</i> 934</li>
                                        <li><i className="fire">{watchlistfireIcon}</i> 223</li>
                                        <li><i className="bookmark-icon">{wtcBookIcon}</i> 34</li>
                                    </ul>
                                </div>
                                <div className="wtch-col-content">
                                    <h3>Looks like your watchlist is empty</h3>
                                    <p>Add your favorite shoes to your watchlist by clicking on the <i className="bookmark-icon">{wtcBookIcon}</i> icon below the image of the sneaker. Make sure your log-in for you list to be saved!</p>
                                    {!this.state.token ? <p>If you saved your watchlist on your account, Please <b><Link to="/" data-toggle="modal" data-target="#signinModal">Login</Link></b></p> : ''}
                                </div>
                            </div>}

                        </div>
                        : ''}
                </div>
            </div>
        )
    }
}

export default withRouter(ProductListing)
