import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { GoogleLogout } from 'react-google-login'
import '../assets/css/font-awesome.min.css'
import '../assets/fonts/fonts.css'
import './Custom.css'
import $ from 'jquery'
import axios from 'axios';
import headerLogo from '../assets/images/KF-Logo.svg'
import headerResLogo from '../assets/images/kf-res-logo.svg'
import closeicon from '../assets/images/closeicon-white.svg'
import searchicon from '../assets/images/search-icon.svg'
import nikeLogo from '../assets/images/nike-logo-white.svg'
import airLogo from '../assets/images/airjordan-logo-white.svg'
import addidasLogo from '../assets/images/addidas-logo-white.svg'
import yezzyLogo from '../assets/images/yeezylogo-white.svg'
import * as APICONST from '../constants/Api';
import * as COMMONCONST from '../constants/Common';
import moment from 'moment-timezone';

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search: '',
      modalOpen: false,
      searchError: false,
      headerMenu: {},
      isWatchListShow: 0,

    }
    this.updateSearch = this.updateSearch.bind(this)
    this.changeSearch = this.changeSearch.bind(this)
    this.redirectLink = this.redirectLink.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.getHeaderMenu = this.getHeaderMenu.bind(this)
    this.changesearchRoute = this.changesearchRoute.bind(this)
    this.openmenu = this.openmenu.bind(this)
    this.getWatchlistReleaseSoon = this.getWatchlistReleaseSoon.bind(this)
    this.blurtoggle = this.blurtoggle.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.search !== this.state.search) {
      this.setState({ search: nextProps.search })
    }
  }

  updateSearch = e => {
    // this.props.updateSearch(this.state.search);
    // localStorage.setItem('search', this.state.search);
    // this.changesearchRoute();
    if (!$('.navbar-toggler').hasClass('collapsed')) {
      $('.navbar-toggler').trigger('click');
    }
    if (this.props.location.pathname !== '/') {
      e.preventDefault()
      this.props.history.push("/");
      localStorage.setItem('search', this.state.search);
    } else {
      localStorage.setItem('search', this.state.search);
    }
  }
  componentDidMount() {
    this.getHeaderMenu();
    this.checkWatchlist();

    // setInterval(() => {
    //   this.setState({
    //     curTime : new Date().toLocaleString(),

    //   })
    // }, 1000)
  }

  changeSearch = e => {
    // this.changesearchRoute();
    this.setState({ search: e.target.value, searchError: false }, () => {
      // this.props.updateSearch(this.state.search)
    })
  }

  changesearchRoute() {
    if (this.props.location.pathname !== '/') {
      this.props.history.push("/");
    }
  }

  updateTopmenu(category, sub_category) {
    this.changesearchRoute();
    this.setState({ search: '' })
    this.props.updateTopmenu(category, sub_category)
    const hideMenu = "hidemenu";
    let subMegaMenu = document.body.querySelector("." + category + "-submenu");
    subMegaMenu.classList.add(hideMenu);

  }

  removemenu(category) {
    const hideMenu = "hidemenu";
    let subMegaMenu = document.body.querySelector("." + category + "-submenu");
    subMegaMenu.classList.add(hideMenu);
    setTimeout(function () {
      $('li').removeClass("hidemenu");
    }, 3000);
  }

  updateTopmenunull() {
    this.props.updateTopmenu('', '')
  }

  openmenu() {
    this.changesearchRoute();
    if (this.props.location.pathname !== '/') {
      setTimeout(() => {
        $('.sidebar').toggleClass('active');
        $('body').toggleClass('sidebar-active');
        $('.navbar-collapse').removeClass('show');
      }, 300);
    } else {
      $('.sidebar').toggleClass('active');
      $('body').toggleClass('sidebar-active');
      $('.navbar-collapse').removeClass('show');
    }
  }

  togglesearchmenu() {
    $(".header-search").toggleClass("active");
    $(".header-search input.form-control").focus();
  }

  blurtoggle() {
    if (this.state.search === '') {
      $(".header-search").removeClass("active");
    }
  }

  getWatchlistReleaseSoon() {
    axios({
      method: "get",
      url: APICONST.ISWATCHLISTRELEASESOON,
      headers: { "content-type": "application/json" },
      params: {
        'watchlist-cookie': COMMONCONST.getCookie('watchlist')
      }
    })
      .then(response => {
        if ((response.data.result.count) !== undefined) {
          this.setState({
            isWatchListShow: response.data.result.count
          });
        }
      })
      .catch(error => { });
  }

  checkWatchlist() {
    this.getWatchlistReleaseSoon()
    this.myInterval = setInterval(() => {
      this.getWatchlistReleaseSoon()
    }, 60000)
  }

  getHeaderMenu() {
    axios({
      method: "get",
      url: APICONST.GETHEADERMENU,
      headers: { "content-type": "application/json" }
    })
      .then(response => {

        if ((response.data.result.data) !== undefined) {
          this.setState({
            headerMenu: response.data.result.data
          });
        }
      })
      .catch(error => { });
  }

  clearSearch() {
    this.setState({ search: '', searchError: false }, () =>
      this.props.updateSearch(this.state.search)
    );
    $(".header-search input.form-control").focus();
    $(".header-search-res input.form-control").focus();
  }
  modalClick() {
    if ($('.modal').hasClass('show')) {
      this.setState({
        modalOpen: true
      })
    }
  }

  redirectLink(e) {
    e.preventDefault()
    const path = e.target.href
    if (path) {
      window.location = path
    } else {
      window.location = '/'
    }
  }


  render() {
    $(function () {
      $(document).on('click', '.closemenu', function (e) {
        console.log('rer');
        $('.closeicon-btn').trigger('click');
      });
    });

    const newuser = (
      <ul className='new-user'>
        <li className="signup-link">
          <Link
            className='nav-link'
            to='#'
            data-toggle='modal'
            data-target='#signupModal'
            onClick={this.modalClick}
          >
            Sign Up
          </Link>
        </li>
        <li className="login-link">
          <Link
            className='nav-link'
            to='#'
            data-toggle='modal'
            data-target='#signinModal'
            onClick={this.modalClick}
          >
            Login
          </Link>
        </li>
      </ul>
    )
    const loggedin = (
      <ul className='logged-in'>

        {this.props.isGoogleSignin ? (
          <>
            <li>
              <Link className='nav-link' to='/user-settings' onClick={this.redirectLink}>
                Settings
            </Link>
            </li>
            {/* <li>
              <Link className='nav-link google' to='#'>
                <GoogleLogout
                  className='logout-head'
                  clientId={this.props.gapi}
                  buttonText='Logout'
                  onLogoutSuccess={this.props.logout}
                  onFailure={this.props.logout}
                  isSignedIn={false}
                ></GoogleLogout>
              </Link>
            </li> */}
          </>
        ) : (
          this.props.isUserLoggedIn ? (
            <>
              <li>
                <Link className='nav-link' to="/user-settings" onClick={this.redirectLink}>Settings</Link>
              </li>
              {/* <li>
                  <Link className='nav-link simple' to='#' onClick={this.props.logout}>Logout</Link>
                </li>  */}
            </>
          ) : (
            <>
              <Link
                className='nav-link signup'
                to='#'
                data-toggle='modal'
                data-target='#signupModal'
              >
                Sign Up
                </Link>
            </>
          )
        )}
      </ul>
    )

    const getJordan = (item, key) => {
      return <div key={key} className="sub-menu-col">
        <ul>
          {item.map((i, k) =>
            <li key={i.key} onClick={() => this.removemenu('jordan')}><Link to={'/' + i.key}>{i.value}</Link></li>
          )}
        </ul>
      </div>
    }

    const getAdidas = (item, key) => {
      return <div key={key} className="sub-menu-col">
        <ul>
          {item.map((i, k) =>
            <li key={i.key} onClick={() => this.removemenu('adidas')}><Link to={'/' + i.key}>{i.value}</Link></li>
          )}
        </ul>
      </div>
    }

    const getYeezy = (item, key) => {
      return <div key={key} className="sub-menu-col">
        <ul>
          {item.map((i, k) =>
            <li key={i.key} onClick={() => this.removemenu('yeezy')}><Link to={'/' + i.key}>{i.value}</Link></li>
          )}
        </ul>
      </div>
    }

    const getNike = (item, key) => {
      return <div key={key} className="sub-menu-col">
        <span className="brand-title">{item.key}</span>
        <ul>
          {item.datavalue.map((i, k) =>
            <li key={i.key} onClick={() => this.removemenu('nike')}><Link className="closemenu" to={'/' + i.key}>{i.value}</Link></li>
          )}
        </ul>
      </div>
    }


    return (
      <header className='header'>
        <div className='container'>
          <div className='top-header row'>
            <div className='left-head col-lg-3 col-4'>
              <Link to='/' className='navbar-brand navbrand-desktop' onClick={this.redirectLink}>
                <i className="logo"><img src={headerLogo} /></i>
              </Link>
              <Link to="/" className='navbar-brand navbrand-res' onClick={this.redirectLink}><i className="logo"><img src={headerResLogo} /></i></Link>
            </div>
            <div className='right-head col-lg-9 col-9'>
              <nav className='navbar navbar-expand-lg navbar-dark'>
                <div
                  className='collapse navbar-collapse'
                >
                  <ul className='navbar-nav megamenu'>
                    <li className='nav-item nike-submenu has-submenu'>
                      <span className='nav-link'
                        onClick={() => {
                          this.removemenu('nike');
                        }}
                      >
                        <Link to="/nike">Nike</Link><span className='sr-only'>(current)</span>
                      </span>
                      <div className="sub-mega-menu">
                        <div className="megamenu-cnt">
                          <div className="menu-col-left">
                            <div className="brand-logo-sct">
                              <img src={nikeLogo} alt=".." />
                            </div>
                          </div>
                          <div className="menu-col-right">
                            {this.state.headerMenu.nike !== undefined ?
                              <>
                                {this.state.headerMenu.nike.map((item, key) =>
                                  getNike(item, key)
                                )}
                              </>
                              : ''}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className='nav-item jordan-submenu has-submenu'>
                      <span className='nav-link'
                        onClick={() => {
                          this.removemenu('jordan');
                        }}
                      >
                        <Link to="/jordan">Jordan</Link>
                      </span>
                      <div className="sub-mega-menu">
                        <div className="megamenu-cnt">
                          <div className="menu-col-left">
                            <div className="brand-logo-sct">
                              <img src={airLogo} alt=".." />
                            </div>
                          </div>
                          <div className="menu-col-right">
                            {this.state.headerMenu.jordan !== undefined ?
                              <>
                                {this.state.headerMenu.jordan.map((item, key) =>
                                  getJordan(item, key)
                                )}
                              </>
                              : ''}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className='nav-item adidas-submenu has-submenu'>
                      <span className='nav-link' onClick={() => {
                        this.removemenu('adidas');
                      }}
                      >
                        <Link to="/adidas">Adidas</Link>
                      </span>
                      <div className="sub-mega-menu">
                        <div className="megamenu-cnt">
                          <div className="menu-col-left">
                            <div className="brand-logo-sct">
                              <img src={addidasLogo} alt=".." />
                            </div>
                          </div>
                          <div className="menu-col-right">
                            {this.state.headerMenu.adidas !== undefined ?
                              <>
                                {this.state.headerMenu.adidas.map((item, key) =>
                                  getAdidas(item, key)
                                )}
                              </>
                              : ''}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className='nav-item yeezy-submenu has-submenu'>
                      <span className='nav-link'
                        onClick={() => {
                          this.removemenu('yeezy');
                        }}
                      >
                        <Link to="/yeezy">Yeezy</Link>
                      </span>
                      <div className="sub-mega-menu">
                        <div className="megamenu-cnt">
                          <div className="menu-col-left">
                            <div className="brand-logo-sct">
                              <img src={yezzyLogo} alt=".." />
                            </div>
                          </div>
                          <div className="menu-col-right">
                            {this.state.headerMenu.yeezy !== undefined ?
                              <>
                                {this.state.headerMenu.yeezy.map((item, key) =>
                                  // <li key={item.key} onClick={() => this.removemenu('yeezy')}><Link to={'/' + item.key}>{item.value}</Link></li>
                                  getYeezy(item, key)
                                )}
                              </>
                              : ''}
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </nav>
              <div className='sidelinks d-none d-lg-block'>
                {this.props.isUserLoggedIn ? loggedin : newuser}
              </div>
              <div className={this.state.isWatchListShow > 0 ? 'nav-watchlist redicon' : 'nav-watchlist'}>
                <Link className='nav-link' to='/watchlist'>Watchlist</Link>
              </div>
              <form
                className='form-inline header-search'
                onSubmit={this.updateSearch}
              >
                <span className="timezone-cnt">{moment().tz("America/New_York").format("MM/DD/YYYY ddd hh:mm a") + ' ET'}</span>
                <input
                  className='form-control'
                  value={this.state.search}
                  onChange={this.changeSearch}
                  type='search'
                  placeholder='Search'
                  aria-label='Search'
                  onBlur={this.blurtoggle}
                />
                {this.state.search === '' ?
                  <span className='btn btn-search' onClick={this.openmenu}></span>
                  :
                  <span className='btn btn-close' onClick={this.clearSearch} id="close-btn"></span>
                }
                <span className='btn btn-search-toggle' onClick={this.togglesearchmenu}><img src={searchicon} /></span>
              </form>
              <div className="d-none closeicon-btn">
                <span><img src={closeicon} alt="..."></img></span>
              </div>
            </div>
            <div className="col-8 col-lg-9 header-res">
              <nav className='navbar navbar-dark'>
                <span className="timezone-cnt">{moment().tz("America/New_York").format("MM/DD/YYYY ddd hh:mm a") + ' ET'}</span>
                <button
                  className='navbar-toggler collapsed'
                  type='button'
                  data-toggle='collapse'
                  data-target='#navbarSupportedContent'
                  aria-controls='navbarSupportedContent'
                  aria-expanded='false'
                  aria-label='Toggle navigation'
                >
                  <span className='menuicon menuicon1'></span>
                  <span className='menuicon menuicon2'></span>
                  <span className='menuicon menuicon3'></span>
                </button>
                <div
                  className='collapse navbar-collapse col-12'
                  id='navbarSupportedContent'
                >
                  <form
                    className='form-inline header-search-res'
                    onSubmit={this.updateSearch}
                  >
                    <input
                      id="searchheader"
                      className='form-control'
                      value={this.state.search}
                      onChange={this.changeSearch}
                      type='search'
                      placeholder='Search'
                      aria-label='Search'
                    />
                    {this.state.search === '' ?
                      <span className='btn btn-search' onClick={this.openmenu}></span>
                      :
                      <span className='btn btn-close' onClick={this.clearSearch} id="close-btn"></span>
                    }
                  </form>
                  <div className={this.state.isWatchListShow > 0 ? 'nav-watchlist redicon' : 'nav-watchlist'}>
                    <Link className='nav-link' to='/watchlist' onClick={() => { $('.closeicon-btn').trigger('click'); }}>
                      <i className='fa fa-bookmark-o'></i>
                      Watchlist
                    </Link>
                  </div>
                  <div className='sidelinks'>
                    {this.props.isUserLoggedIn ? loggedin : newuser}
                  </div>
                  <ul className='navbar-nav'>
                    <li className="nav-label">
                      Release Dates
                    </li>
                    <li className='nav-item'>
                      <span className='nav-link' onClick={() => { $('.closeicon-btn').trigger('click'); }}>
                        <Link to="/nike">Nike</Link><span className='sr-only'>(current)</span>
                      </span>
                    </li>
                    <li className='nav-item dropdown'>
                      <span className='nav-link' onClick={() => { $('.closeicon-btn').trigger('click'); }}>
                        <Link to="/jordan">Jordan</Link>
                      </span>
                    </li>
                    <li className='nav-item'>
                      <span className='nav-link' onClick={() => { $('.closeicon-btn').trigger('click'); }}>
                        <Link to="/adidas">Adidas</Link>
                      </span>
                    </li>
                    <li className='nav-item'>
                      <span className='nav-link' onClick={() => { $('.closeicon-btn').trigger('click'); }}>
                        <Link to="/yeezy">Yeezy</Link>
                      </span>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default withRouter(Header)
