import React, { Fragment } from "react";
// import logo from './logo.svg';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Header from "./components/Header";
import MainContent from "./components/MainContent";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import ForgotPwd from "./components/ForgotPwd";
import VerifyEmail from "./components/VerifyEmail";
import Footer from "./components/Footer";
import $ from "jquery";
import axios from "axios";
import "./App.css";
import "./assets/fonts/fonts.css";
import ResetPassword from "./components/ResetPassword";
import * as APICONST from './constants/Api';
import UserSettings from './components/UserSettings';
import NotFound404 from './components/NotFound404';
import ScrollToTop from './components/ScrollToTop';
import ProductDetail from './components/ProductDetail';
import WatchList from './components/WatchList';
import CmsPage from './components/CmsPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import About from './components/About';
import TermsAndConditions from './components/TermsAndConditions';
import IndividualModal from './components/individualModal';
import MostPopular from './components/mostPopular';
import Details from './components/Details';
import AppleLogin from './components/AppleLogin';
import * as COMMONCONST from './constants/Common';
import ReactGA from 'react-ga';

axios.interceptors.request.use(req => {
  if (COMMONCONST.getAuthorizationToken()) {
    req.headers.authorization = COMMONCONST.getAuthorizationToken();
  }
  return req;
});
axios.interceptors.response.use((response) => {
  return response;
}, error => {
  if (error.response.status === 401) {
    localStorage.setItem("rememberMe", false);
    localStorage.removeItem("isGoogleSignin");
    localStorage.removeItem("email");
    localStorage.removeItem("token");
    window.location = '/';
  }
  return Promise.reject(error);
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gapi: COMMONCONST.GOOGLE_LOGIN_TOKEN,
      userDetails: {},
      isUserLoggedIn: localStorage.getItem("token") != null ? true : false,
      modalOpen: false,
      isGoogleSignin: localStorage.getItem("isGoogleSignin") != null ? true : false,
      search: "",
      category: "",
      sub_category: "",
      // resetToken: this.props.match.params.resettoken
      //   ? this.props.match.params.resettoken
      //   : "",
      isWatchlist: COMMONCONST.getCookie('watchlist')
    };

    this.updateSearch = this.updateSearch.bind(this);
    this.updateTopmenu = this.updateTopmenu.bind(this);
    this.clearTopMenu = this.clearTopMenu.bind(this);
    let is_login = localStorage.getItem("token") != null ? true : false
    if(is_login && COMMONCONST.getCookie('user-account') == 'delete-account') {
        COMMONCONST.setCookieValue('user-account', '');
        window.location = '/user-settings';
    }
  }

  updateSearch(search) {
    this.setState({ search: search, category: '', sub_category: '' });
  }

  clearTopMenu() {
    if (this.state.category !== '' && this.state.sub_category !== '') {
      this.setState({ category: '', sub_category: '' });
    }
  }
  updateTopmenu(category, sub_category) {
    this.setState({ category: category, sub_category: sub_category, search: '' });
  }

  componentDidMount() {
    COMMONCONST.loadImage();
  }

  componentDidUpdate() {
    COMMONCONST.loadImage();
  }

  render() {
    const responseGoogle = (response) => {
      if (response.profileObj) {
        this.setState({
          userDetails: response.profileObj,
          isUserLoggedIn: true,
          isGoogleSignin: true,
        });
        if (this.state.isUserLoggedIn && this.state.userDetails) {
          const userData = this.state.userDetails;
          axios({
            method: "post",
            url: APICONST.LOGIN,
            headers: { "content-type": "application/json" },
            data: {
              email: userData.email,
              google_id: userData.googleId,
              first_name: userData.givenName,
              last_name: userData.familyName,
              access_token: response.accessToken,
              isWatchlist: this.state.isWatchlist
            },
          })
            .then((response) => {
              if (response.data.result.message !== " ") {
                localStorage.setItem("rememberMe", true);
                localStorage.setItem("email", response.data.result.data.email);
                localStorage.setItem("token", response.data.result.data.token);
                localStorage.setItem("isGoogleSignin", true);
                window.location = '/'
              }
            })
            .catch((error) => {
              this.setState({ error: error.response.data.result.message });
            });

          $(".modal, .modal-backdrop").hide();
          $("body").removeClass("modal-open").removeAttr("style");
        }
      }
    };

    const logout = () => {
      const token = localStorage.getItem('token');
      axios({
        method: 'get',
        url:
          APICONST.LOGOUT + '?token=' + token,
        headers: { 'content-type': 'application/json' }
      })
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
        this.setState({ isUserLoggedIn: false });
        this.setState({ isGoogleSignin: false });
        localStorage.setItem("rememberMe", false);
        localStorage.removeItem("isGoogleSignin");
        localStorage.removeItem("email");
        localStorage.removeItem("token");
        COMMONCONST.setCookieValue('user-account', '');
        $("#signupModal").hide();
        window.location = '/';
    };
    return (
      <Router>
        <ScrollToTop />
        <div className="App">
          <Header
            responseGoogle={responseGoogle}
            logout={logout}
            {...this.state}
            updateSearch={this.updateSearch}
            updateTopmenu={this.updateTopmenu}
          />

          <Switch>

            <Route exact path="/verify-updated-email/:token" render={props =>
              <Fragment>
                {/* <MainContent search={this.state.search} sub_category={this.state.sub_category} category={this.state.category} />     */}
                <VerifyEmail token={props.match.params.token} path={props.match.path} />
              </Fragment>
            } />

            <Route exact path="/verify-email/:token" render={props =>
              <Fragment>
                {/* <MainContent search={this.state.search} sub_category={this.state.sub_category} category={this.state.category} />     */}
                <VerifyEmail token={props.match.params.token} path={props.match.path} />
              </Fragment>
            } />

            <Route exact path="/reset-password/:resettoken" render={props =>
              <Fragment>
                <MainContent search={this.state.search} sub_category={this.state.sub_category} category={this.state.category} />
                <ResetPassword resettoken={props.match.params.resettoken} />
              </Fragment>
            } />

            {/* <Route exact path="/productdetail/">
              <ProductDetail />
            </Route> */}

            <Route key="about" exact path="/about-us/">
              <About />
              {/* <CmsPage page='about-us' /> */}
            </Route>

            <Route key="privacy-policy" exact path="/privacy-policy/">
              <PrivacyPolicy />
              {/* <CmsPage page='privacy-policy' /> */}
            </Route>

            <Route key="terms-conditions" exact path="/terms-conditions/">
              <TermsAndConditions />
              {/* <CmsPage page='terms-conditions' /> */}
            </Route>

            <Route exact path="/watchlist">
              <WatchList />
            </Route>

            <Route exact path="/apple-login/:token" render={props =>
              <AppleLogin token={props.match.params.token} />
            } />

            {/* <Route exact path="/model/:slug" render={props =>            
              <IndividualModal slug={props.match.params.slug}  />
            } /> */}

            <Route exact path="/nike/:slug" render={props =>
              <IndividualModal slug={props.match.params.slug} />
            } />
            <Route exact path="/yeezy/:slug" render={props =>
              <IndividualModal slug={props.match.params.slug} />
            } />
            <Route exact path="/adidas/:slug" render={props =>
              <IndividualModal slug={props.match.params.slug} />
            } />
            <Route exact path="/jordan/:slug" render={props =>
              <IndividualModal slug={props.match.params.slug} />
            } />

            <Route exact path="/most-popular">
              <MostPopular />
            </Route>

            <Route exact path="/">
              <MainContent isUserLoggedIn={this.state.isUserLoggedIn} updateMainSearch={this.updateSearch} search={this.state.search} sub_category={this.state.sub_category} category={this.state.category} clearTopMenu={this.clearTopMenu} />
            </Route>

            <Route exact path="/nike/:slug" render={props =>
              <MainContent isUserLoggedIn={this.state.isUserLoggedIn} updateMainSearch={this.updateSearch} showslider='false' search={this.state.search} sub_category={props.match.params.slug} category='nike' clearTopMenu={this.clearTopMenu} />
            } />
            <Route exact path="/yeezy/:slug" render={props =>
              <MainContent isUserLoggedIn={this.state.isUserLoggedIn} updateMainSearch={this.updateSearch} showslider='false' search={this.state.search} sub_category={props.match.params.slug} category='yeezy' clearTopMenu={this.clearTopMenu} />
            } />
            <Route exact path="/adidas/:slug" render={props =>
              <MainContent isUserLoggedIn={this.state.isUserLoggedIn} updateMainSearch={this.updateSearch} showslider='false' search={this.state.search} sub_category={props.match.params.slug} category='adidas' clearTopMenu={this.clearTopMenu} />
            } />
            <Route exact path="/jordan/:slug" render={props =>
              <MainContent isUserLoggedIn={this.state.isUserLoggedIn} updateMainSearch={this.updateSearch} showslider='false' search={this.state.search} sub_category={props.match.params.slug} category='jordan' clearTopMenu={this.clearTopMenu} />
            } />

            <Route exact path="/nike" render={props =>
              <MainContent isUserLoggedIn={this.state.isUserLoggedIn} updateMainSearch={this.updateSearch} showslider='false' search={this.state.search} sub_category={props.match.params.slug} category='nike' clearTopMenu={this.clearTopMenu} />
            } />
            <Route exact path="/yeezy" render={props =>
              <MainContent isUserLoggedIn={this.state.isUserLoggedIn} updateMainSearch={this.updateSearch} showslider='false' search={this.state.search} sub_category={props.match.params.slug} category='yeezy' clearTopMenu={this.clearTopMenu} />
            } />
            <Route exact path="/adidas" render={props =>
              <MainContent isUserLoggedIn={this.state.isUserLoggedIn} updateMainSearch={this.updateSearch} showslider='false' search={this.state.search} sub_category={props.match.params.slug} category='adidas' clearTopMenu={this.clearTopMenu} />
            } />
            <Route exact path="/jordan" render={props =>
              <MainContent isUserLoggedIn={this.state.isUserLoggedIn} updateMainSearch={this.updateSearch} showslider='false' search={this.state.search} sub_category={props.match.params.slug} category='jordan' clearTopMenu={this.clearTopMenu} />
            } />

            <Route exact path="/user-settings">
              <UserSettings responseGoogle={responseGoogle} isGoogleSignin={this.state.isGoogleSignin} gapi={this.state.gapi} logout={logout}
                logout={logout} {...this.props} />
            </Route>

            <Route exact path="/ ">
              <Redirect to='/' />
            </Route>

            <Route exact path="/q" render={props =>
              <Redirect to={`/${props.location.search}`} />
            } />

            <Route exact path="/:slug" render={props =>
              // <ProductDetail slug={props.match.params.slug} />
              <Details slug={props.match.params.slug} />
            } />

            <Route>
              <NotFound404 updateSearch={this.updateSearch} />
            </Route>


          </Switch>
          {!this.state.isUserLoggedIn ? (
            <SignIn responseGoogle={responseGoogle} {...this.state} />
          ) : (
            ""
          )}

          {!this.state.isUserLoggedIn ? (
            <SignUp responseGoogle={responseGoogle} {...this.state} />
          ) : (
            ""
          )}

          <ForgotPwd />

          <Footer {...this.state} logout={logout} />
        </div>
      </Router>
    );
  }
}

export default App;
